import React, { createContext, useContext, useRef } from 'react';
import useBudgetState from './budget/useBudgetState'
import useModalExitPromptState from './modalExitPrompt/useModalExitPromptState'
import useUserState from './user/useUserState'

const AppContext = createContext()

export const AppDataProvider = ({ children }) => {
  const [budget, budgetDispatch] = useBudgetState()
  const [modalExitPrompt, modalExitPromptDispatch] = useModalExitPromptState()
  const [user, userDispatch] = useUserState()
  // El usuario inicia sesion para realizar una reserva
  // Se usa ref para no depender de la actualizacion de useState
  const logsInForAReservation = useRef(null);

  return (
    <AppContext.Provider
      value={{
        budget,
        budgetDispatch,
        modalExitPrompt,
        modalExitPromptDispatch,
        user,
        userDispatch,
        logsInForAReservation
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  return useContext(AppContext)
}
