import { useReducer } from 'react'
import { SET_CLEAR_DATA, SET_FORM_HAS_DATA, SET_CONFIRM_EXIT } from './constants'

const initialModalExitPromptState = {
  formHasData: false,
  confirmExitPrompt: false
}

function modalExitPromptReducer(state, action) {
  switch (action.type) {
    case SET_FORM_HAS_DATA:
      return {
        ...state,
        formHasData: action.data ? true : false,
      }
    case SET_CLEAR_DATA:
      return {
        ...initialModalExitPromptState
      }
    case SET_CONFIRM_EXIT:
      return {
        ...state,
        confirmExitPrompt: action.data,
      }
    default:
      return state
  }
}

export default function useModalExitPromptState() {
  return useReducer(modalExitPromptReducer, initialModalExitPromptState)
}
