import { useReducer } from 'react'
import { SET_USER_DATA, SET_REGISTER_DATA, SET_SHOW_LOGIN_LABEL, SET_USER_ROUTE } from './constants'

const initialUserState = {
  profile: {},
  isLoggedIn: false,
  prevRoute: null,
  user: {
    email: null,
    password: null,
    code: null,
  },
  showLoginLabel: false,
  prevBudget:false
}

function userReducer(state, action) {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        profile: {
          ...action.data.profile
        },
        isLoggedIn: action.data.isLoggedIn,
    }
    case SET_REGISTER_DATA:
      return {
        ...state,
        user: {
          email: action.data.email ? action.data.email : state.user.email,
          password: action.data.password ? action.data.password : state.user.password,
          code: action.data.code ? action.data.code : state.user.code,
        },
    }
    case SET_USER_ROUTE:
      return {
        ...state,
        prevRoute: action.data.prevRoute ? action.data.prevRoute : state.user.prevRoute,
      }
    case SET_SHOW_LOGIN_LABEL:
      return {
        ...state,
        showLoginLabel: action.data.showLoginLabel ? action.data.showLoginLabel : state.user.showLoginLabel
    }
    default:
      return state
  }
}

export default function useUserState() {
  return useReducer(userReducer, initialUserState)
}
