import { useReducer } from 'react';
import { BUDGET } from '../../utils/constants';
import {
  SET_CAR_DATA,
  SET_BACK_BUTTON,
  SET_ADD_USED_CAR,
  SET_CLEAR_USED_CAR,
  DELETE_USED_CAR,
  SET_CREDIT_LINE,
  SET_CREDIT_LINE_CONDITION,
  SET_EDIT_USED_CAR,
  UPDATE_USED_CAR,
  DELETE_CREDIT_LINE,
  SET_ORDER_CREDITS_LINES,
  SET_DELIVERY_COSTS,
  SET_FINAL_PRICE,
  SET_FORM_HAS_DATA,
  RESET_BUDGET_STATE,
  UPDATE_STOCK_FIELDS,
} from './constants';

const initialBudgetState = {
  car: {},
  // Gastos de entrega (patentamiento / transferencia)
  deliveryCosts: 0,

  // Linea de financiacion seleccionada
  creditLine: {
    id: null,
    // Gastos de financiacion
    financialCosts: 0,
    // Monto financiado
    financedAmount: 0,
    // Valor de cuota promedio
    avgQuoteValue: null,
    // Valor de la primera cuota
    firstQuoteValue: null,

    // Condicion seleccionada de la linea financiera
    condition: {
      id: null
    },
  },
  orderCreditsLines:BUDGET.CREDIT_LINE_SORT.MAX_CAPITAL_DESC,
  onBackClick: () => { },
  backLabel: null,
  usedCars:[],
  editCar: null,
  financedAmount: {},
  finalPrice: null,
  formHasData: false,
};

// Se crea una copia profunda del estado inicial
// para poder reestablecerlo al salir
const initialStateCopy = JSON.parse(JSON.stringify(initialBudgetState));

function budgetReducer(prevState, action) {
  switch (action.type) {
    case RESET_BUDGET_STATE:
      // Se crea una nueva copia del estado
      // para mantener intacta la copia original
      const resetedState = JSON.parse(JSON.stringify(initialStateCopy));

      // Se devuelve el nuevo estado vacio
      return resetedState;
    case SET_CREDIT_LINE:
      return {
        ...prevState,
        creditLine: {
          ...prevState.creditLine,
          ...action.data
        }
      };
    case SET_CREDIT_LINE_CONDITION:
      return {
        ...prevState,
        creditLine: {
          ...prevState.creditLine,
          condition: {
            ...prevState.creditLine.condition,
            ...action.data
          }
        }
      };
    case SET_BACK_BUTTON:
      const newState = {
        ...prevState
      };

      if (action.data.onBackClick) {
        newState.onBackClick = action.data.onBackClick;
      }

      if (action.data.backLabel) {
        newState.backLabel = action.data.backLabel;
      }

      return newState;
    case SET_CAR_DATA:
      return {
        ...prevState,
        car: { ...action.data }
      };
    case SET_ADD_USED_CAR:
      return {
        ...prevState,
        usedCars:[...prevState.usedCars,action.data]
      };
    case SET_CLEAR_USED_CAR:
      return {
        ...prevState,
        usedCars:[]
      };
    case DELETE_USED_CAR:
      return {
        ...prevState,
        usedCars:[...prevState.usedCars.filter(usedCar => usedCar.id !== action.data.id)]
      };
    case SET_EDIT_USED_CAR:
      return {
        ...prevState,
        editCar: action.data
      };
    case UPDATE_USED_CAR:
      return {
        ...prevState,
        usedCars:[...prevState.usedCars.map((usedCar) => usedCar.id === action.data.id ? action.data : usedCar)]
      };
    case DELETE_CREDIT_LINE:
      return {
        ...prevState,
        creditLine:{ ...initialBudgetState.creditLine },
        orderCreditsLines:BUDGET.CREDIT_LINE_SORT.MAX_CAPITAL_DESC,
      };
    case SET_ORDER_CREDITS_LINES:
      return {
        ...prevState,
        orderCreditsLines:action.data.orderCreditsLines,
      };
    case SET_DELIVERY_COSTS:
      return {
        ...prevState,
        deliveryCosts: action.data
      };
    case SET_FINAL_PRICE:
      return {
        ...prevState,
        finalPrice: action.data.totalPrice,
        creditLine:{
          ...prevState.creditLine,
          financialCosts:
            action.data.priceFinancingExpenses
              ? action.data.priceFinancingExpenses
              : prevState.creditLine.financialCosts
        }
      };
    case SET_FORM_HAS_DATA:
      return {
        ...prevState,
        formHasData: action.data ? true : false,
      };
    case UPDATE_STOCK_FIELDS:
      const stockCopy = JSON.parse(JSON.stringify(prevState.car.stock));
      const stockIndex = stockCopy.findIndex(stock => stock.id === prevState.car.selectedStock);
      const adaptedStock = { ...stockCopy[stockIndex], ...action.data };
      stockCopy[stockIndex] = adaptedStock;

      return {
        ...prevState,
        car: {
          ...prevState.car,
          selectedStock: action.data.id,
          stock: stockCopy
        }
      };
    default:
      throw new Error('Invalid action');
  }
}

export default function useBudgetState() {
  return useReducer(budgetReducer, initialBudgetState);
}
