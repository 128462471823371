/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('typeface-poppins');

export { wrapRootElement } from './src/api/wrapRootElement';

export const onServiceWorkerUpdateReady = () => {
  window.location.reload();
};
