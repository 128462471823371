export const SET_CAR_DATA = 'setVehicleInfo';
export const SET_BACK_BUTTON = 'setBackData';
export const SET_ADD_USED_CAR = 'setAddUsedCar';
export const SET_CLEAR_USED_CAR = 'serClearUserdCar';
export const DELETE_USED_CAR = 'deleteUsedCar';
export const SET_CREDIT_LINE = 'setCreditLine';
export const SET_CREDIT_LINE_CONDITION = 'setCreditLineCondition';
export const SET_EDIT_USED_CAR = 'setEditUsedCar';
export const UPDATE_USED_CAR = 'updateUsedCar';
export const DELETE_CREDIT_LINE = 'deleteCreditLine';
export const SET_ORDER_CREDITS_LINES = 'setOrderCreditsLines';
export const SET_DELIVERY_COSTS = 'setDeliveryCosts';
export const SET_FINAL_PRICE = 'setFinalPrice';
export const SET_FORM_HAS_DATA = 'setFormHasData';
export const RESET_BUDGET_STATE = 'resetBudgetState';
export const UPDATE_STOCK_FIELDS = 'updateStockFields';
