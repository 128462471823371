import { navigate as gatsbyNavigate } from 'gatsby';
import { isBrowser } from '../utils/ssr.utilis';
import { navigationRoutes } from './navigationRoutes.enum';

export const navigate = (route, options = {}, path) => {
  const { state } = options;

  if (route === path) {
    window.location.reload();
  } else if (state) {
    gatsbyNavigate(route, { state });
  } else {
    gatsbyNavigate(route);
  }
};

export const goToHome = () => {
  navigate(navigationRoutes.HOME);
};

export const goToRecuperar = () => {
  navigate(navigationRoutes.RECUPERAR);
};

export const goToRegistro = () => {
  navigate(navigationRoutes.REGISTRO_REVENTA);
};

export const goToIngresar = (state, path) => {
  navigate(navigationRoutes.INGRESAR, { state }, path);
};

export const goToCarDetail = (car) => {
  navigate(`${
    navigationRoutes.DETALLE}${
    car.brand.name.toLowerCase()}/${
    car.model.name.toLowerCase()}/${
    car.version.name.toLowerCase()}/?id=${car.id}`);
};

export const goToComparador = () => {
  navigate(navigationRoutes.COMPARADOR);
};

export function goToCompare(newCar) {
  navigate('/comparador', {
    state: { car:newCar }
  });
}

export const goToCotizador = () => {
  navigate(navigationRoutes.COTIZADOR);
};

export const goToMainCotizador = () => {
  navigate(navigationRoutes.COTIZADOR_MAIN);
};

export const goToVendeTuAuto = () => {
  navigate(navigationRoutes.VENDE);
};

export const goToRegistroParticular = (state) => {
  navigate(navigationRoutes.REGISTRO_PARTICULAR.ROOT,{ state });
};


export const goToPrevious = (previousRoute) => {
  navigate(previousRoute);
};

export const goToCatalog = (params) => {
  let query = '', concat = '?';

  if (params){
    if (params.segmento) {
      query += `${concat}segmento=${params.segmento}`;
      concat = '&';
    }

    if (params.marca) {
      query += `${concat}marca=${params.marca}`;
      concat = '&';
    }

    if (params.modelo) {
      query += `${concat}modelo=${params.modelo}`;
      concat = '&';
    }

    if (params.keyword) {
      query += `${concat}search=${params.keyword}`;
    }

    if(params.condicion) {
      query += `${concat}condicion=${params.condicion}`;
    }
  }

  navigate(`${navigationRoutes.CATALOGO}${query}`);
};

export const goToCatalogAutosList = (params = {}) => {
  let queryString = '';

  for (let key in params) {
    if (params[key]) {
      if (key === 'keyword') {
        queryString += `&${key}=${params[key]}`;
      } else {
        queryString += `&${key}Label=${params[key].label}&${key}Value=${params[key].value}`;
      }
    }
  }

  navigate(`${navigationRoutes.CATALOGO_AUTOS}?showResults=true${queryString}`);
};

export const goToCatalogAutosDetail = ( productId ) => {
  if (!productId) navigate(navigationRoutes.CATALOGO_AUTOS);
  navigate(`${navigationRoutes.CATALOGO_AUTOS}?productId=${productId}`);
};

export const goToAdminPanel = () => {
  navigate(navigationRoutes.ADMIN);
};

export const goToPresupuesto = (car, preventExitPrompt = false) => {
  navigate(`${
    navigationRoutes.PRESUPUESTO.ROOT}${
    car.brand.name.toLowerCase()}/${
    car.model.name.toLowerCase()}/${
    car.version.name.toLowerCase()}/?productId=${
    car.id}&stockId=${car.selectedStock}`,{ state: { preventExitPrompt } });
};

export const goToFinancingLines = (routeMode) => {
  navigate(`${navigationRoutes.PRESUPUESTO.ROOT}${navigationRoutes.PRESUPUESTO.FINANCING_LINES}/${routeMode}`);
};

export const goToCuenta = (index = null) => {
  navigate(navigationRoutes.CUENTA.ROOT,{ state:{ index } });
};

export const goToCambioPasswordExitoso = () => {
  navigate(navigationRoutes.CUENTA.ROOT + '/cambio-exitoso');
};

//LINK PARA IR A LINEA FINANCIERA
export const goToFinancingLineDetails = (routeMode) => {
  navigate(`${navigationRoutes.PRESUPUESTO.ROOT}${navigationRoutes.PRESUPUESTO.LINE_DETAILS}/${routeMode}`);
};

/* BROWSER ONLY */
export function insertParam(key, value) {
  if (isBrowser) {
    // kvp looks like ['key1=value1', 'key2=value2', ...]
    const kvp = document.location.search.substr(1).split('&');
    if (typeof(key) === 'object') {
      for (let [_key, _value] of Object.entries(key)) {
        key = encodeURIComponent(_key);
        value = encodeURIComponent(_value);

        let i = 0;

        for(; i < kvp.length; i++){
          if (kvp[i].startsWith(key + '=')) {
            if (value === 'null' || value === 'undefined') {
              kvp[i] = '';
              break;
            } else {
              let pair = kvp[i].split('=');
              pair[1] = value;
              kvp[i] = pair.join('=');
              break;
            }
          }
        }

        if(i >= kvp.length && value !== 'null' && value !== 'undefined'){
          kvp[kvp.length] = [key,value].join('=');
        }
      }
    } else {
      key = encodeURIComponent(key);
      value = encodeURIComponent(value);

      let i = 0;

      for(; i < kvp.length; i++){
        if (kvp[i].startsWith(key + '=')) {
          if (value === 'null' || value === 'undefined') {
            kvp[i] = '';
            break;
          } else {
            let pair = kvp[i].split('=');
            pair[1] = value;
            kvp[i] = pair.join('=');
            break;
          }
        }
      }

      if(i >= kvp.length && value !== 'null' && value !== 'undefined'){
        kvp[kvp.length] = [key,value].join('=');
      }
    }
    // can return this or...
    let params = kvp.filter(item => item !== '').join('&');

    // reload page with new params
    navigate(`${document.location.pathname}?${params}`);
  }
}
