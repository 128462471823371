module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Carmuv","short_name":"Carmuv","start_url":"/","background_color":"#7D25FF","theme_color":"#7D25FF","display":"minimal-ui","icon":"src/assets/images/app-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e9614669038d5d4d6d171ef7bdd5db8d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"defaultCrumb":{"location":{"pathname":"/"},"crumbLabel":"Home","crumbSeparator":" / "},"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/financiacion","crumbLabel":"Financiá"},{"pathname":"/catalogo","crumbLabel":"Comprá"},{"pathname":"/catalogo-autos","crumbLabel":"Catálogo"},{"pathname":"/comparador","crumbLabel":"Compará"},{"pathname":"/cotizar-mi-auto","crumbLabel":"Vendé"},{"pathname":"/cotiza","crumbLabel":"Vendé"},{"pathname":"/cuenta","crumbLabel":"Mi cuenta"},{"pathname":"/cotizador-autos","crumbLabel":"Cotizador"}],"trailingSlashes":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
