export const navigationRoutes = {
  REGISTRO_REVENTA: '/registro-reventa/',
  RECUPERAR: '/recuperar-contrasenia/',
  INGRESAR: '/ingresar/',
  CATALOGO: '/catalogo/',
  CATALOGO_AUTOS: '/catalogo-autos/',
  DETALLE: '/detalle/',
  COMPARADOR: '/comparador/',
  COTIZADOR: '/cotiza/',
  COTIZADOR_MAIN: '/cotizador-autos/',
  ADMIN: '/admin/productos/',
  HOME: '/',
  VENDE:'/cotizar-mi-auto/',
  CUENTA: {
    ROOT:'/cuenta'
  },
  PRESUPUESTO: {
    ROOT: '/presupuesto/',
    FINANCING_LINES: 'lineas-financieras',
    LINE_DETAILS: 'detalles-linea'
  },
  REGISTRO_PARTICULAR: {
    ROOT: '/registro-particular/',
    ACTIVACION: 'activacion',
  }
};
