import { goToHome, goToAdminPanel, goToPrevious } from './navigation';

export const isAuthenticated = (roles) => (user) => {
  return roles.includes(user.profile.role_name);
};

export const USER_ROLES =  {
  ADMIN: 'Carmuv Admin',
  RESALE: 'Carmuv Reventa',
  PUBLIC: 'Carmuv Particular',
};

export const ResaleRoles = [USER_ROLES.RESALE, USER_ROLES.ADMIN];
export const AdminRoles = [USER_ROLES.ADMIN];
export const isAdmin = isAuthenticated(AdminRoles);
export const isResale = isAuthenticated(ResaleRoles);

export function isPublicUser(roleName) {
  // Si el usuario NO está logeado o su rol logeado es Particular
  return !roleName || roleName === USER_ROLES.PUBLIC;
}

export function isResellerUser(roleName) {
  return !isPublicUser(roleName);
}

export const login = (loginData, apolloClient, prevRoute) => {
  apolloClient.resetStore();
  setTimeout(() => {
    // Not sure why, but without setTimeout writeData not working
    apolloClient.writeData({
      data: {
        token: loginData.token,
        profile: loginData.profile,
        isLoggedIn: true
      }
    });
    setTimeout(() => {
      if (isAdmin(loginData)) {
        goToAdminPanel();
      } else if (!prevRoute) {
        goToHome()
      } else {
        goToPrevious(prevRoute);
      }
    }, 100);


  }, 0);
};

export const logout = (apolloClient, redirect = true) => {
  apolloClient.resetStore();
  apolloClient.writeData({ data:INITIAL_AUTH_STATE });
  sessionStorage.setItem('wasLoggedIn','false');
  if(redirect) goToHome();
};

export const INITIAL_AUTH_STATE = {
  token: null,
  profile: null,
  isLoggedIn: false
};

