import React from 'react';
import fetch from 'isomorphic-fetch';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';

import { ApolloProvider } from '@apollo/react-hooks';
import { baseTheme } from '../assets/theme';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import { INITIAL_AUTH_STATE } from '../api/authorization';
import { AppDataProvider } from '../context/AppContext';

const cache = new InMemoryCache();
const client = new ApolloClient({
  cache,
  link: createUploadLink({
    uri: process.env.GATSBY_CARMUV_PROXY_URL,
    credentials: 'include',
    fetch,
  }),
});

cache.writeData({
  data: INITIAL_AUTH_STATE,
});

const theme = createTheme(baseTheme);

export const wrapRootElement = ({ element }) => {
  return (
    <AppDataProvider>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          {element}
        </ApolloProvider>
      </ThemeProvider>
    </AppDataProvider>
  );
};
